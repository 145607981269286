<template>
  <div>
    <!-- MODAL BLOCK SPOTS -->
    <b-modal id="block-spots-list" ref="block-spots-list" size="xl" hide-footer hide-header :no-enforce-focus="true" @hidden="clearFields" @show="loadData">
      <template #default>
        <div class="d-flex">
          <div class="table-responsive" style="max-width: 83%">
            <b-table
              ref="selectableTable"
              :fields="[
                { key: 'index', label: $t('booking.total') },
                { key: 'name', label: $t('booking.totalDuration') + '/' + $t('table.name') },
                { key: 'duration', label: $t('booking.booking') },
                { key: 'commercial_version_type_id', label: $t('booking.versions') },
                { key: 'placement_type_id', label: $t('booking.types') },
                ...(auctionBlock ? [{ key: 'coefficient', label: $t('auction.bidCoefficient') }] : []),
                ...(!auctionBlock ? [{ key: 'actual_position', label: $t('booking.fixPositions') }] : []),
                ,
              ]"
              :items="spots.spots.filter((el) => el.id)"
              select-mode="single"
              selectable
              hover
              show-empty
              class="spots-table table-sm ws-nowrap small"
              :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              :tbody-tr-class="rowClass"
              @row-selected="onSpotRowSelected"
            >
              <!-- HEADS -->
              <template #head(index)="data">
                <p class="mb-2">{{ data.label }}</p>
                {{ spots.spots.length > 0 ? spots.spots.filter((el) => el.is_active).length : '' }}
              </template>
              <template #head(name)="data">
                <div class="resizable-div__name">
                  <p class="mb-2">{{ data.label }}</p>
                  {{ spots.spots.length > 0 ? spots.spots.reduce((acc, current) => acc + current.duration, 0) : '' }}
                </div>
              </template>
              <template #head(duration)="data">
                <p class="mb-2">{{ data.label }}</p>
                {{ spots.spots.length > 0 ? spots.spots.reduce((acc, current) => (current.is_active ? acc + current.duration : acc + 0), 0) : '' }}
              </template>
              <template #head(commercial_version_type_id)="data">
                <p class="mb-2">{{ data.label }}</p>
                <abbr
                  :title="
                    spots.durations_by_commercial_version_type.reduce((acc, current, i) => (i === 0 ? acc + current.name : acc + ' / ' + current.name), '')
                  "
                >
                  {{
                    spots.durations_by_commercial_version_type.reduce(
                      (acc, current, i) => (i === 0 ? acc + current.active_duration : acc + ' / ' + current.active_duration),
                      ''
                    )
                  }}
                </abbr>
              </template>
              <template #head(placement_type_id)="data">
                <p class="mb-2">{{ data.label }}</p>
                <abbr :title="spots.durations_by_placement_type.reduce((acc, current, i) => (i === 0 ? acc + current.name : acc + ' / ' + current.name), '')">
                  {{
                    spots.durations_by_placement_type.reduce(
                      (acc, current, i) => (i === 0 ? acc + current.active_duration : acc + ' / ' + current.active_duration),
                      ''
                    )
                  }}
                </abbr>
              </template>
              <template #head(actual_position)="data">
                <p class="mb-2">{{ data.label }}</p>
                {{ spots.spots.length > 0 ? spots.spots.reduce((acc, current) => (current.position ? acc + 1 : acc + 0), 0) : '' }}
              </template>
              <!-- CELLS -->
              <template #cell(index)="data">
                {{ data.item.is_active ? data.index + 1 : '-' }}
              </template>
              <template #cell(coefficient)="data"> {{ data.item.auction_coeff != null ? (data.item.auction_coeff * 100).toFixed(0) + '%' : '' }}</template>
              <template #cell(name)="data">
                <div class="position-relative" :style="{ paddingLeft: doubleSpotsCount * 2 + 16 + 'px' }">
                  <template v-if="data.item.height">
                    <span class="ds-span-line" :style="{ height: data.item.height + 'px', left: data.item.left + 'px' }"></span>
                  </template>
                  <span v-if="isAdmin" class="text-opacity-75 small"> <abbr :title="$t('table.commercialID')">C:</abbr>[{{ data.item.commercial_id }}] </span>
                  <span v-if="isAdmin && showSpotID" class="text-opacity-75 small"> <abbr :title="$t('table.spotId')">S:</abbr> [{{ data.item.id }}] </span>
                  {{ data.item.name }}
                  <CoBrandBadge v-if="data.item.co_branding_discount !== null" />
                </div>
              </template>
              <template #cell(commercial_version_type_id)="data">
                {{
                  commercialVersionTypes.data.find((type) => type.id === data.item.commercial_version_type_id)
                    ? commercialVersionTypes.data.find((type) => type.id === data.item.commercial_version_type_id).name
                    : ''
                }}
              </template>
              <template #cell(placement_type_id)="data">
                <span :class="setPlacementClass(data.item.placement_type_id)">
                  {{
                    placementType.find((type) => type.id === data.item.placement_type_id)
                      ? placementType.find((type) => type.id === data.item.placement_type_id).name
                      : ''
                  }}
                </span>
              </template>
              <template #cell(actual_position)="data">
                <span :class="{ 'bg-position-warning': data.item.position && data.item.equal_fact_position === 0 }">
                  {{ data.item.is_active ? data.item.position : 'Wait' }}
                </span>
              </template>
              <!-- IF NO SPOTS -->
              <template #empty>
                <h5 class="text-center">{{ $t('booking.noSpots') }}</h5>
              </template>
            </b-table>
          </div>
          <div class="w-auto pl-4" style="max-width: none">
            <div class="d-flex flex-column gap-2 position-sticky d-flex align-items-stretch" style="top: 20px">
              <p class="h5" style="max-width: 160px">{{ $t('booking.blockSpots', [isAdmin ? `[${blockId}]` : '']) }}</p>
              <template v-if="$checkPermissions('scope.spot_booking_unlimited')">
                <!-- (!auctionBlock || (auctionBlock && isAdmin))) -->
                <b-button
                  v-if="$checkPermissions('spot.up')"
                  size="sm"
                  :disabled="!(selectedSpot && !selectedSpot.position && selectedSpot.is_active) || closedDay || isBusy"
                  @click="upSpot"
                >
                  {{ $t('booking.up') }}
                </b-button>
                <b-button
                  v-if="$checkPermissions('spot.down')"
                  size="sm"
                  :disabled="!(selectedSpot && !selectedSpot.position && selectedSpot.is_active) || closedDay || isBusy"
                  @click="downSpot"
                >
                  {{ $t('booking.down') }}
                </b-button>
              </template>
              <template v-if="$checkPermissions('scope.spot_booking_limited')">
                <div
                  v-if="
                    !auctionBlock &&
                    $checkPermissions('spot.fix') &&
                    (canFixLowPriority ||
                      (!canFixLowPriority && selectedSpot && (selectedSpot.placement_type_id === 1 || selectedSpot.placement_type_id === 2)))
                  "
                  class="d-flex gap-2"
                >
                  <b-form-select v-model="selectedPosition" size="sm" :options="premiumPositionOptions" class="w-50"></b-form-select>
                  <b-button
                    size="sm"
                    class="w-50"
                    :disabled="!(selectedPosition && selectedSpot && selectedSpot.is_active) || closedDay || isBusy"
                    @click="fixSpot"
                  >
                    {{ $t('booking.fix') }}
                  </b-button>
                </div>
                <b-button
                  v-if="
                    !auctionBlock &&
                    $checkPermissions('spot.unfix') &&
                    (canFixLowPriority ||
                      (!canFixLowPriority && selectedSpot && (selectedSpot.placement_type_id === 1 || selectedSpot.placement_type_id === 2)))
                  "
                  size="sm"
                  :disabled="!(selectedSpot && selectedSpot.position && selectedSpot.is_active) || closedDay || isBusy"
                  @click="unfixSpot"
                >
                  {{ $t('booking.unFix') }}
                </b-button>

                <b-button
                  v-if="!auctionBlock && $checkPermissions('spot.create')"
                  size="sm"
                  :disabled="!(selectedSpot && selectedSpot.is_active) || closedDay || isBusy"
                  @click="copySpot"
                >
                  {{ $t('broadcast.copy') }}
                </b-button>
                <b-button
                  v-if="!auctionBlock && $checkPermissions('spot.create')"
                  v-b-modal.confirm-paste
                  size="sm"
                  :disabled="!copiedSpot || closedDay || isBusy"
                >
                  {{ $t('booking.paste') }}
                </b-button>
                <b-button
                  v-if="$checkPermissions('spot.swap') && !auctionBlock"
                  v-b-toggle.sidebar-replace-spot
                  size="sm"
                  :disabled="!selectedSpot || closedDay || isBusy"
                >
                  {{ $t('booking.replaceSpot') }}
                </b-button>
                <b-button
                  v-if="canDelete"
                  v-b-modal.confirm-delete-spot
                  size="sm"
                  :disabled="!selectedSpot || closedDay || isBusy || (auctionBlock && coefficient != null && !isAdminOrSalesHouse)"
                >
                  {{ $t('table.delete') }}
                </b-button>
                <div class="w-100 p-auto">
                  <b-input-group
                    v-if="auctionBlock"
                    class="mx-auto border border-danger d-flex flex-column justify-content-center align-items-center p-2 justify-content-between"
                    style="max-width: fit-content !important"
                    :class="selectedSpot && selectedSpot.placement_type_id != 3 && selectedSpot.placement_type_id != 4 ? '' : 'hidden-panel'"
                  >
                    <!-- <label style="font-size: 12px">Step coefficient: {{ blockStepPercents }}%</label> -->
                    <!-- <NumberStepper v-model="coefficient" :step="blockStepPercents" :min="coefficientDefault"></NumberStepper> -->

                    <b-input style="box-sizing: border-box; width: 100%" readonly :value="(coefficient * 100).toFixed(0) + '%'" />

                    <div class="d-flex flex-row mt-2" style="gap: 4px !important; padding: 0px auto; width: fit-content">
                      <button
                        :disabled="coefficient <= selectedSpot?.auction_coeff || (selectedSpot?.auction_coeff == null && coefficient == 0)"
                        class="btn btn-outline-danger btn-sm"
                        size="sm"
                        @click="coefficient = Number((Number(coefficient) - blockStep).toFixed(2))"
                      >
                        <b-icon icon="caret-left" />
                      </button>
                      <button
                        :disabled="coefficient <= selectedSpot?.auction_coeff || (selectedSpot?.auction_coeff == null && coefficient == 0)"
                        class="btn btn-outline-danger btn-sm"
                        style="width: fit-content; white-space: nowrap"
                        size="sm"
                        v-b-modal.bid-confirm
                      >
                        {{ $t('table.save') }}
                      </button>
                      <button class="btn btn-outline-danger btn-sm" size="sm" @click="coefficient = (Number(coefficient) + blockStep).toFixed(2)">
                        <b-icon icon="caret-right" />
                      </button>
                    </div>
                    <!-- <div class="font-weight-bold">
                    <span class="user-select-none"> {{ (coefficient * 100).toFixed(0) }} %</span>
                  </div> -->
                  </b-input-group>
                </div>
              </template>
              <b-button v-if="canDeleteAll && !auctionBlock" v-b-modal.confirm-delete-all-spots class="mt-2" size="sm" :disabled="closedDay || isBusy">
                {{ $t('table.deleteAll') }}
              </b-button>
              <!-- <b-button
                  v-if="$checkPermissions('spot.create')"
                  size="sm"
                  disabled
                  class="mt-4"
                >
                  {{ $t("booking.editDouble") }}
                </b-button> -->
              <b-button size="sm" class="mt-4" @click="hideModalBlockSpots">
                {{ $t('table.close') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- MODAL BLOCK SPOTS -->

    <!-- CONFIRM PASTE -->
    <b-modal
      id="confirm-paste"
      ref="confirm-paste"
      :title="$t('table.pasteSpot')"
      :ok-title="$t('booking.paste')"
      :ok-disabled="isBusy"
      :cancel-title="$t('table.cancel')"
      auto-focus-button="ok"
      @ok="confirmPaste"
    >
      {{ $t('table.confirmPaste') }}: {{ copiedSpot ? copiedSpot.name + ' (' + copiedSpot.duration + ')' : '' }}?
    </b-modal>

    <!-- CONFIRM DELETE -->
    <b-modal
      id="confirm-delete-spot"
      ref="confirm-delete-spot"
      :title="$t('table.delete')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      :ok-disabled="isBusy"
      auto-focus-button="ok"
      @ok="deleteSpot"
    >
      {{ $t('table.confirmDeleteSpot') }}: <span class="text-danger">{{ selectedSpot ? selectedSpot.name + ' (' + selectedSpot.duration + ')' : '' }}</span>
      ?
    </b-modal>
    <!-- CONFIRM DELETE ALL SPOTS -->
    <b-modal
      id="confirm-delete-all-spots"
      ref="confirm-delete-all-spots"
      size="sm"
      :title="$t('table.deleteAll')"
      :ok-title="$t('table.yes')"
      :ok-disabled="isBusy"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      @ok="deleteAllSpots"
    >
      {{ $t('table.areYouSure') }}
    </b-modal>

    <ModalBidConfirm @increaseBidConfirm="increaseBidConfirm" />

    <ModalReplaceSpot :selected-spot="selectedSpot" @update="$store.dispatch('GET_BLOCK_SPOTS', blockId)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import errorsHandler from '@/utils/errorsHandler';
import setPlacementClass from '@/mixins/setPlacementClass';
import showDoubleSpots from '@/mixins/showDoubleSpots';
import { BTable, VBToggle } from 'bootstrap-vue';
import ModalReplaceSpot from '@/components/Booking/ModalReplaceSpot';
import NumberStepper from '../NumberStepper.vue';
import ModalBidConfirm from '../ModalBidConfirm.vue';

export default {
  name: 'ModalBlockSpots',
  components: { BTable, ModalReplaceSpot, CoBrandBadge: () => import('@/components/CoBrandBadge.vue'), NumberStepper, ModalBidConfirm },
  directives: { 'b-toggle': VBToggle },
  mixins: [setPlacementClass, showDoubleSpots],
  props: {
    blockId: {
      type: Number,
      default: undefined,
    },
    layout: {
      type: String,
      default: '',
    },
    closedDay: {
      type: Boolean,
    },
    blockType: {
      type: Number,
      default: 1,
    },
    blockStep: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      coefficient: null,
      selectedSpot: null,
      selectedPosition: null,
      premiumPositionOptions: [
        { text: '', value: null },
        { text: '1F', value: '1F' },
        { text: '2F', value: '2F' },
        { text: '3F', value: '3F' },
        { text: '3L', value: '3L' },
        { text: '2L', value: '2L' },
        { text: '1L', value: '1L' },
      ],
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      target: 'getTarget',
      commercialVersionTypes: 'getCommercialVersionTypes',
      spots: 'getBlockSpotsList',
      placementType: 'getPlacementType',
      copiedSpot: 'getCopiedSpot',
      isAdmin: 'getIsCurrentUserAdmin',
      isSalesHouse: 'getIsCurrentUserSalesHouseManager',
      showSpotID: 'getShowSpotID',
    }),
    isAdminOrSalesHouse() {
      return this.isAdmin || this.isSalesHouse;
    },
    canDelete: function () {
      return this.$checkPermissions('spot.delete');
    },
    canDeleteAll: function () {
      return this.$checkPermissions('spot.delete_for_block');
    },
    canFixLowPriority: function () {
      return this.$checkPermissions('spot.fix_low_priority');
    },
    blockStepPercents: function () {
      return this.blockStep * 100;
    },
    auctionBlock: function () {
      return this.blockType == 2;
    },
  },
  watch: {
    spots() {
      if (this.spots) {
        this.doubleSpotsCount = 0;
        this.usedDsId = [];
        this.showDoubleSpots(this.spots, 34.6, 17.6); // rowHeight and innerDivHeight
      }
    },
    selectedSpot() {
      this.coefficient = this.selectedSpot?.auction_coeff;
    },
  },
  destroyed() {
    this.$store.commit('clearBlockSpots');
  },
  methods: {
    async loadData() {
      if (!this.commercialVersionTypes || (this.commercialVersionTypes && this.commercialVersionTypes.data.length < 1))
        await this.$store.dispatch('GET_COMM_VER_TYPES', { per_page: 1000 });
    },
    hideModalBlockSpots() {
      this.$bvModal.hide('block-spots-list');
    },

    async increaseBidConfirm() {
      const formData = {
        auction_coeff: this.coefficient,
      };
      await this.$store.dispatch('PUT_INCREASE_BID', {
        blockId: this.blockId,
        spotId: this.selectedSpot.id,
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('auction.bidIncreased'),
            text: this.name,
          });
          this.selectedSpot = null;
          this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
          if (this.layout === 'booking') this.$emit('updateWgrp');
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (!item.is_active) return 'text-muted';
      if (item.coProhibition) return 'prohibition-warning';
    },

    // Add selected spot from table
    onSpotRowSelected(items) {
      this.selectedSpot = items[0] || null;
      if (this.selectedSpot != null) {
        this.coefficient = this.selectedSpot.auction_coeff || 0;
        this.coefficientDefault = this.coefficient;
      }
    },

    clearFields() {
      this.selectedSpot = null;
      this.selectedPosition = null;
    },

    async upSpot() {
      this.isBusy = true;
      const blockId = this.blockId;
      const formData = this.selectedSpot.id;
      await this.$store.dispatch('POST_SPOT_UP', {
        blockId,
        formData,
        handler: (res) => {
          if (res.data.warnings) {
            this.$notify({
              type: 'warning',
              duration: 5000,
              title: this.$i18n.t('alert.spotUp'),
              text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
            });
          } else {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.spotUp'),
            });
          }
          this.selectedSpot = null;
          this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },

    async downSpot() {
      this.isBusy = true;
      const blockId = this.blockId;
      const formData = this.selectedSpot.id;
      await this.$store.dispatch('POST_SPOT_DOWN', {
        blockId,
        formData,
        handler: (res) => {
          if (res.data.warnings) {
            this.$notify({
              type: 'warning',
              duration: 5000,
              title: this.$i18n.t('alert.spotDown'),
              text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
            });
          } else {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.spotDown'),
            });
          }
          this.selectedSpot = null;
          this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },

    async fixSpot() {
      this.isBusy = true;
      const blockId = this.blockId;
      const spotId = this.selectedSpot.id;
      const formData = { position: this.selectedPosition };
      await this.$store.dispatch('POST_SPOT_FIX', {
        blockId,
        spotId,
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotFixed') + ' ' + this.selectedPosition,
          });
          this.selectedSpot = null;
          this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
          this.$emit('postSpot');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },
    async unfixSpot() {
      this.isBusy = true;
      const blockId = this.blockId;
      const spotId = this.selectedSpot.id;
      await this.$store.dispatch('POST_SPOT_UNFIX', {
        blockId,
        spotId,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotUnfixed'),
          });
          this.selectedSpot = null;
          this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
          this.$emit('postSpot');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },

    copySpot() {
      this.$store.commit('setCopiedSpot', this.selectedSpot);
      this.$notify({
        type: 'success',
        title: this.$i18n.t('alert.spotCopied'),
      });
    },

    async confirmPaste() {
      if (this.copiedSpot) {
        this.isBusy = true;
        const blockId = this.blockId;
        if (this.copiedSpot.double_spot_id) {
          const formData = {
            double_spot_id: this.copiedSpot.double_spot_id,
            position: null,
            mediaplan_id: this.copiedSpot.mediaplan_id,
          };
          await this.$store.dispatch('POST_DOUBLE_SPOT_IN_BLOCKS', {
            blockId,
            formData,
            handler: (res) => {
              if (res.data.warnings) {
                this.$notify({
                  type: 'warning',
                  duration: 5000,
                  title: this.$i18n.t('alert.addCommercials'),
                  text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
                });
              } else {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.addCommercials'),
                });
              }
              this.$emit('postSpot');
              if (this.layout === 'booking') this.$emit('updateWgrp');
              this.$store.commit('clearCopiedSpot');
              this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
            },
            handlerError: (errors) => {
              errorsHandler(errors, this.$notify);
            },
          });
        } else {
          const formData = {
            commercial_id: this.copiedSpot.commercial_id,
            position: null,
            mediaplan_id: this.copiedSpot.mediaplan_id,
            force: true,
          };
          await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
            blockId,
            formData,
            handler: (res) => {
              if (res.data.warnings) {
                this.$notify({
                  type: 'warning',
                  duration: 5000,
                  title: this.$i18n.t('alert.addCommercials'),
                  text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
                });
              } else {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.addCommercials'),
                });
              }
              this.$emit('postSpot');
              if (this.layout === 'booking') this.$emit('updateWgrp');
              this.$store.commit('clearCopiedSpot');
              this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
            },
            handlerError: (errors) => {
              errorsHandler(errors, this.$notify);
            },
          });
        }
        this.isBusy = false;
      }
    },

    hideModalConfirmDeleteSpot() {
      this.$bvModal.hide('confirm-delete-spot');
    },

    async deleteSpot() {
      this.isBusy = true;
      const blockId = this.blockId;
      const commercialId = this.selectedSpot?.id;
      if (commercialId) {
        await this.$store.dispatch('DELETE_COMMERCIAL_IN_BLOCKS', {
          blockId,
          commercialId,
          handler: (res) => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.deleteSpot'),
            });
            this.selectedSpot = null;
            this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
            this.$emit('postSpot');
            if (this.layout === 'booking') this.$emit('updateWgrp');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.isBusy = false;
    },

    async deleteAllSpots() {
      this.isBusy = true;
      const blockId = this.blockId;
      await this.$store.dispatch('DELETE_SPOTS_IN_BLOCK', {
        blockId,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteAllSpots'),
          });
          this.$store.dispatch('GET_BLOCK_SPOTS', this.blockId);
          this.$emit('postSpot');
          if (this.layout === 'booking') this.$emit('updateWgrp');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },
  },
};
</script>

<style lang="sass" src="../../assets/sass/mediaPlanPlacementTypes.sass"></style>
<style lang="sass">
#block-spots-list
  tr.prohibition-warning
    background-color: rgba(255,255,0,0.1)
  .text-opacity-75
    opacity: 0.75
</style>

<style lang="sass" scoped>
.ds-span-line
  position: absolute
  top: 0
  left: 0
  content: ' '
  display: inline-block
  background-color: #dc3545
  width: 2.5px
  z-index: 2

.resizable-div__name
  overflow: auto
  min-width: 380px

thead:hover .resizable-div__name
  resize: horizontal

.spots-table thead th:nth-child(2)
  min-width: 380px

.spots-table tbody td:nth-child(2)
  max-width: 380px
  overflow: hidden

.hidden-panel
  visibility: hidden
</style>
